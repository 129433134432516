import { forwardRef, ReactNode } from 'react';
import { Gray_Light_900 } from '@croquiscom/zds';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

import { Gray_Light_White, Typography } from '@common/styles';

export interface ToastBarProps {
  message?: ReactNode;
  style?: React.CSSProperties;
}

export const ToastBar = forwardRef<HTMLDivElement, ToastBarProps>(({ message, style, children }, ref) => {
  return (
    <Container ref={ref} style={style}>
      <Global
        styles={css`
          :root {
            --toast-bar-background-color: ${Gray_Light_900};
            --toast-bar-color: ${Gray_Light_White};
          }
        `}
      />
      <Wrapper className={Typography.BODY_15_MEDIUM}>{message ?? children}</Wrapper>
    </Container>
  );
});

const Container = styled.div`
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  padding: 0 20px;
  padding-bottom: calc(env(safe-area-inset-bottom));
  padding-bottom: calc(constant(safe-area-inset-bottom));
  z-index: 2100;
`;

const Wrapper = styled.div`
  background: var(--toast-bar-background-color);
  color: var(--toast-bar-color);
  text-align: center;
  padding: 15px 16px 16px;
  border-radius: 4px;
  white-space: pre-wrap;
  max-width: 560px;
  margin: 0 auto;
`;
