import { useEffect, useRef } from 'react';
import { Cookies } from 'react-cookie';
import { compareWithAppVersion } from '@croquiscom-pvt/web2app';

import { useFeatureList } from '../query/useFeatureList';

/**
 * A, B군의 경우 기존대로 미로그인 시 찜하기 가능
 * C군의 경우 미로그인 시 앱 이벤트 호출 > 로그인 완료 > 찜하기 토스트 > callback을 받아 찜하기 store 업데이트가 되어야 함
 */
const NON_LOGIN_LIMIT_ABT_KEY = 'ab_zzim_non_login_limited_C';

export function useNonLoginLimitABT() {
  const cookies = new Cookies();
  const user_uuid = cookies.get('ZIGZAGUUID');
  const isABT = useRef(false);

  const { feature_list } = useFeatureList({ uuid: user_uuid });

  useEffect(() => {
    isABT.current = feature_list.includes(NON_LOGIN_LIMIT_ABT_KEY) && compareWithAppVersion('8.13.0');
  }, [feature_list]);

  return isABT.current;
}
