import { useRouter } from 'next/router';

import { isKakaotalk } from '@common/device-manager';
import { useKakaoAPI } from '@common/hooks';
import { airbridgeTracker } from '@common/marketing-tracker';
import { Toast } from '@common/overlay-manager';
import { loginKakao } from '@common/utils';

import { useLikeProductConfig } from '../context/ConfigContext';
import { useLoginQuery } from '../query/useLoginQuery';
import { useMutateWebLikeProduct } from '../query/useMutateWebLikeProduct';

interface UseLikeConfig {
  onLikeChange: (checked: boolean, productId: string) => void;
  webToastStyle?: React.CSSProperties;
}

type AirbridgeConfig = {
  productID: unknown;
  productName: unknown;
  categoryID: string;
  categoryName: string;
  brandID: unknown;
  brandName: unknown;
  price: unknown;
  currency?: unknown;
};

/**
 * ZigZag Web에서 like 동작 시 전달하는 값
 * @docs https://www.notion.so/croquis/widget-like-product-bb6ac56bf316499ea5f85e30faadad03
 */
export type WebLikeProductPayload = {
  checked: boolean;
  productData: {
    catalog_product_id: string;
    shop_id: string;
  };
  airbridge: AirbridgeConfig;
  is_posty?: boolean;
  sendLog?: (checked: boolean) => void;
};

export const useWebLikeProduct = ({ onLikeChange, webToastStyle }: UseLikeConfig) => {
  const { saveMutate, removeMutate } = useMutateWebLikeProduct();
  const router = useRouter();
  const { loginUrl } = useLikeProductConfig();
  const loginQuery = useLoginQuery();

  useKakaoAPI(() => isKakaotalk());

  const goToLogin = () => {
    const redirectLoginUrl = loginUrl();

    if (!redirectLoginUrl) {
      return;
    }
    if (isKakaotalk()) {
      loginKakao(redirectLoginUrl);
      return;
    }
    // NOTE: next 기반이 아닌 경우 (e.g. 직진배송관) router가 없기 때문에 옵셔널 처리.
    router?.push(redirectLoginUrl);
  };

  const sendAirbridgeEvents = (log: AirbridgeConfig) => {
    if (log) {
      airbridgeTracker.events.send('airbridge.addToWishlist', {
        semanticAttributes: {
          products: [{ ...log, currency: 'KRW' }],
        },
        label: log.categoryName.split(',')[0],
      });
    }
  };

  const onWebLikeProduct = ({ checked, productData, airbridge, is_posty, sendLog }: WebLikeProductPayload) => {
    // NOTE: 통합체크아웃 포스티는 미로그인 상태로 찜하기 가능 및 통합체크아웃은 항상 로그인 상태로 페이지 접근하여 로그인 여부 bypass
    if (!loginQuery.data && !is_posty) {
      goToLogin();
      return;
    }

    onLikeChange(checked, productData.catalog_product_id);
    sendLog?.(checked);

    if (checked) {
      Toast.show('찜한 상품에 추가했어요', undefined, webToastStyle);
      saveMutate(productData);
      sendAirbridgeEvents(airbridge);
      return;
    }
    Toast.show('찜한 상품에서 삭제했어요', undefined, webToastStyle);
    removeMutate(productData);
  };

  return {
    onWebLikeProduct,
  };
};
