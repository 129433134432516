import { useEffect, useMemo, useRef, useState } from 'react';

import { LikeMeta, likeMetaStore } from '../store/LikeStore';

function createOrderStore() {
  let order = 1;
  const orderList: number[] = [];
  let groupOrder = 1;
  const groupOrderList = [groupOrder];
  return {
    getOrder: () => {
      return order;
    },
    incOrder: () => {
      order++;
      orderList.push(order);
    },
    getGroupOrder: () => {
      return groupOrder;
    },
    incGroupOrder: () => {
      groupOrder++;
      groupOrderList.push(groupOrder);
    },
    getIdsToRemove: () => {
      if (groupOrderList.length > 2) {
        return groupOrderList.shift();
      }
    },
  };
}
const { getGroupOrder, getOrder, incGroupOrder, incOrder, getIdsToRemove } = createOrderStore();

export function initLikeIds(ids: string[], groupOrderId?: number) {
  likeMetaStore.setState((prev) => {
    return ids?.reduce<LikeMeta>((acc, id) => {
      if (acc[id]) {
        acc[id] = {
          has: acc[id].has,
          inView: false,
          order: getOrder(),
          groupOrder: groupOrderId ?? getGroupOrder(),
          id,
        };
        return acc;
      } else if (!acc[id] && !acc[id]?.order && !acc[id]?.groupOrder) {
        acc[id] = { has: true, inView: false, order: getOrder(), groupOrder: groupOrderId ?? getGroupOrder(), id };
        incOrder();
        return acc;
      }
      return acc;
    }, prev);
  });
  if (typeof groupOrderId === 'undefined') {
    incGroupOrder();
  }
  return ids;
}

interface Config<T> {
  data?: T;
  convert: (data: T) => string[];
  groupKeys?: Array<string | number>;
}

/**
 * 상품목록에서 기존에 유저가 찜하기 했던 상품목록 id을 가져와 store에 저장하는 hook
 * @param data: 찜하기 여부를 확인해야하는 상품목록 데이터
 * @param convert: data에서 상품 id만 추출하는 함수
 * @docs https://www.notion.so/croquis/widget-like-product-bb6ac56bf316499ea5f85e30faadad03#8f9fcc93dc494be7893d31aea5ed5e7c
 */
export const useInitLikedProducts = <T>(config: Config<T>): void => {
  const latestKeys = config.groupKeys?.join() ?? '';
  const prevKeys = useRef(latestKeys);
  const [groupOrderId, setId] = useState(() => {
    const result = getGroupOrder();
    incGroupOrder();
    return result;
  });
  const prevGroupOrderId = useRef(groupOrderId);
  const data = useMemo(() => config.data, [config.data]);
  const latestData = useRef(data);
  latestData.current = data;
  const convert = useRef(config.convert);
  const prevList = useRef<string[]>([]);
  convert.current = config.convert;

  useEffect(() => {
    if (convert.current && data) {
      const list = convert.current(data);
      if (list.join() !== prevList.current.join()) {
        initLikeIds(list, groupOrderId);
      }
      return () => {
        if (groupOrderId !== prevGroupOrderId.current) {
          prevList.current = list;
        }
      };
    }
  }, [convert, data, groupOrderId]);
  useEffect(() => {
    return () => {
      likeMetaStore.setState((prev) => {
        const isOverCount = Object.keys(prev).length > 1500;
        let idToremove: number | undefined;
        if (isOverCount) {
          idToremove = getIdsToRemove();
        }
        for (const id in prev) {
          const prevItem = prev[id];
          prevItem.inView = false;
          if (idToremove === prevItem.groupOrder) {
            delete prev[id];
          }
        }
        return prev;
      });
    };
  }, [groupOrderId]);

  if (prevKeys.current !== latestKeys) {
    prevGroupOrderId.current = getGroupOrder();
    setId(() => {
      const result = getGroupOrder();
      incGroupOrder();
      return result;
    });
    prevKeys.current = latestKeys;
  }
};
