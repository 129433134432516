import { useState } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

/** FIXME: Circular */
import { I18nKey, I18nValues } from '@common/i18n';

import { createOverlayComp, OverlayCompProps } from '../common/util/createOverlayComp';
import { ToastBar } from '../ToastBar/ToastBar';

const AnimatedToastBar = motion(ToastBar);

interface ToastComponentProps {
  message?: string | React.ReactNode;
  /**
   *  @description 등장 시간
   * @default 1500ms
   */
  time?: number;
}

function ToastComponent({ message, resolve, dispose, time = 1500, style }: ToastComponentProps & OverlayCompProps) {
  const [active, setActive] = useState(true);

  return (
    <AnimatePresence>
      {active && (
        <AnimatedToastBar
          message={message}
          style={style}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onAnimationComplete={(value: any) => {
            if (value?.opacity === 1) {
              setTimeout(() => {
                setActive(false);
              }, time);
            }
            if (value?.opacity === 0) {
              resolve?.('');
              dispose?.();
            }
          }}
        />
      )}
    </AnimatePresence>
  );
}

function ToastComponentWithT<N extends Namespace>({
  message,
  ns,
  values,
  ...props
}: ToastComponentProps & OverlayCompProps & { ns: N; values?: I18nValues }) {
  const { t } = useTranslation(ns);

  return (
    <ToastComponent
      {...{
        message: t(message as I18nKey<N>, values),
        ...props,
      }}
    />
  );
}

export function renderToast<N extends Namespace>(
  message: string | React.ReactNode,
  ns?: N,
  values?: I18nValues,
  time?: number,
  style?: React.CSSProperties,
) {
  return ({ resolve, dispose }: OverlayCompProps) => {
    return ns ? (
      <ToastComponentWithT {...{ message, resolve, dispose, time, ns, values, style }} />
    ) : (
      <ToastComponent {...{ message, resolve, dispose, time, style }} />
    );
  };
}

export type ConfirmReturnValue = true;
const openToast = createOverlayComp<string, string | React.ReactNode>(renderToast);

export class Toast {
  static show(message: string | React.ReactNode, time?: number, style?: React.CSSProperties) {
    return openToast(message, undefined, undefined, time, style);
  }
  static showWithT<N extends Namespace>(ns: N, message: I18nKey<N>, values?: I18nValues, time?: number) {
    return openToast(message as unknown as string, ns, values, time);
  }
}
