import { useCallback } from 'react';

import { removeUmdSavedProduct, saveWebUmdProduct } from '../graphql/product-umd.2';
import type { UmdProductIdentifiersInput } from '../graphql/product-umd_types.2';
import { likeStatusStore } from '../store/LikeStore';

export const useMutateWebLikeProduct = () => {
  const handleSaveMutate = useCallback(async (productData: UmdProductIdentifiersInput) => {
    const catalog_product_id = productData.catalog_product_id ?? '';
    likeStatusStore.setState({ [catalog_product_id]: true });
    try {
      await saveWebUmdProduct({ product_ids: productData });
    } catch (error) {
      likeStatusStore.setState({ [catalog_product_id]: false });
      console.error(error);
    }
  }, []);

  const handleRemoveMutate = useCallback(async (productData: UmdProductIdentifiersInput) => {
    const catalog_product_id = productData.catalog_product_id ?? '';
    likeStatusStore.setState({ [catalog_product_id]: false });
    try {
      const { data } = await removeUmdSavedProduct({ product_info: { product_ids_list: [productData] } });
      if (!data.removeWebUmdSavedProduct) {
        likeStatusStore.setState({ [catalog_product_id]: true });
      }
    } catch (error) {
      likeStatusStore.setState({ [catalog_product_id]: true });
      console.error(error);
    }
  }, []);

  return { saveMutate: handleSaveMutate, removeMutate: handleRemoveMutate };
};
