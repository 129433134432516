import { useQuery, useQueryClient } from 'react-query';

import { getMetaFeatureList } from '../graphql/meta-feature-list.2';

export const META_FEATURE_QUERY_KEY = 'getMetaFeature';

export const useFeatureList = ({ uuid }: { uuid: string }) => {
  const queryClient = useQueryClient();
  const cache_data = queryClient.getQueryData(META_FEATURE_QUERY_KEY);

  const response = useQuery({
    queryKey: [META_FEATURE_QUERY_KEY],
    queryFn: async function () {
      const { data } = await getMetaFeatureList(
        {
          data_version: 7,
          platfrom: '',
          ui: uuid?.split('.')[0],
        },
        { show_alert: false },
      );
      return data.metadata.feature_list;
    },
    enabled: !cache_data,
  });
  return {
    feature_list: response.error || !response.data ? [] : response.data,
  };
};
