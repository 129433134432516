import { compareWithAppVersion } from '@croquiscom/web2app';

import { useZigzag } from '@common/device-manager';

import { InAppLikeProductPayload, useInAppLikeProduct } from '../hooks/useInAppLikeProduct';
import { useWebLikeProduct, WebLikeProductPayload } from '../hooks/useWebLikeProduct';
import { likeStatusStore } from '../store/LikeStore';

interface UseLikeProductServiceConfig {
  webToastStyle?: React.CSSProperties;
}

/**
 * 찜하기 추가 및 해제를 담당하는 함수를 리턴하는 hook
 * @returns onLikeProduct: 통합 찜하기 추가 및 해제, onInAppLikeProduct: 웹뷰(In App) 찜하기 추가 및 해제, onWebLikeProduct: 웹 찜하기 추가 및 해제
 * @description onLikeProduct 함수를 사용하는 것을 권장합니다. 인자에 따라 InApp, Web 환경에 맞춰 동작합니다. 만약의 상황을 위해 onInAppLikeProduct, onWebLikeProduct 코드를 제공합니다.
 * @docs https://www.notion.so/croquis/widget-like-product-bb6ac56bf316499ea5f85e30faadad03#9ca635bb9f58406996ff270080d01190
 */
export const useLikeProductService = (config?: UseLikeProductServiceConfig) => {
  const onLikeChange = (checked: boolean, productId: string) => {
    likeStatusStore.setState({ [productId]: checked });
  };
  const { onInAppLikeProduct } = useInAppLikeProduct({ onLikeChange });
  const { onWebLikeProduct } = useWebLikeProduct({ onLikeChange, webToastStyle: config?.webToastStyle });
  const isZigzagApp = useZigzag();

  /**
   * 찜하기 추가 및 해제 함수
   * @param inAppLikeProductPayload: ZigZag App에서 사용하는 웹뷰에서 like 동작 시 전달하는 값
   * @param webLikeProductPayload: ZigZag Web에서 like 동작 시 전달하는 값
   * @docs https://www.notion.so/croquis/widget-like-product-bb6ac56bf316499ea5f85e30faadad03#9ca635bb9f58406996ff270080d01190
   */
  const onLikeProduct = ({
    inAppLikeProductPayload,
    webLikeProductPayload,
  }: {
    inAppLikeProductPayload?: InAppLikeProductPayload;
    webLikeProductPayload?: WebLikeProductPayload;
  }) => {
    if (isZigzagApp && inAppLikeProductPayload) {
      if (inAppLikeProductPayload.sendLog && !compareWithAppVersion('7.85.0')) {
        inAppLikeProductPayload.sendLog(inAppLikeProductPayload.like);
      }

      onInAppLikeProduct(inAppLikeProductPayload);
      return;
    }

    if (webLikeProductPayload) {
      onWebLikeProduct(webLikeProductPayload);
    }
  };

  return {
    onInAppLikeProduct,
    onWebLikeProduct,
    onLikeProduct,
  };
};
